import bedzevi from "./card-images/bedzevi.jpg";
import darovi from "./card-images/darovi.jpg";
import deciji from "./card-images/deciji.jpg";
import fioka from "./card-images/fioka.jpg";
import fiseci from "./card-images/fiseci.jpg";
import jedanCvet from "./card-images/jedanCvet.jpg";
import korpice from "./card-images/korpice.jpg";
import koverte from "./card-images/koverte.jpg";
import maturski from "./card-images/maturski.jpg";
import novogodisnji from "./card-images/novogodisnji.jpg";
import okrugle from "./card-images/okrugle.jpg";
import okrugleBezPoklopca from "./card-images/okrugleBezPoklopca.jpg";
import okrugleBezPoklopcaSaPregradom from "./card-images/okrugleBezPoklopcaSaPregradom.jpg";
import okrugleKlasik from "./card-images/okrugleKlasik.jpg";
import okrugleSaPregradom from "./card-images/okrugleSaPregradom.jpg";
import panorama from "./card-images/panorama.jpg";
import pinjate from "./card-images/pinjate.jpg";
import poklonGosti from "./card-images/poklonGosti.jpg";
import postoljaislagalice from "./card-images/postoljaislagalice.jpg";
import pravougaone from "./card-images/pravougaone.jpg";
import pravougaoneBezPoklopca from "./card-images/pravougaoneBezPoklopca.jpg";
import pravougaoneBezPoklopcaSaPregradom from "./card-images/pravougaoneBezPoklopcaSaPregradom.jpg";
import pravougaoneKlasik from "./card-images/pravougaoneKlasik.jpg";
import pravougaoneSaPregradom from "./card-images/pravougaoneSaPregradom.jpg";
import providne from "./card-images/providne.jpg";
import providneOkrugle from "./card-images/providneOkrugle.jpg";
import providnePravougaone from "./card-images/providnePravougaone.jpg";
import razno from "./card-images/razno.jpg";
import slovaibrojevi from "./card-images/slovaibrojevi.jpg";
import srce from "./card-images/srce.jpg";
import svadbeni from "./card-images/svadbeni.jpg";
import vencanjeikrstenje from "./card-images/vencanjeikrstenje.jpg";

export const navbarItems = [
    {
        title: "Početna",
        path: "home"
    }, {
        title: "Proizvodi",
        path: "products"
    }, {
        title: "Galerija",
        path: "gallery"
    }
];

export const types = [
    {
        id: 0,
        name: "standard",
        label: "Standard"
    }, {
        id: 1,
        name: "plis",
        label: "Pliš"
    }, {
        id: 2,
        name: "hamer",
        label: "Hamer"
    }, {
        id: 3,
        name: "dekor",
        label: "Sa dekoracijom"
    }, {
        id: 4,
        name: "reljef",
        label: "Reljef"
    }, {
        id: 5,
        name: "random",
        label: "Random"
    }
];

export const images = {
    bedzevi: bedzevi,
    darovi: darovi,
    deciji: deciji,
    fioka: fioka,
    fiseci: fiseci,
    jedanCvet: jedanCvet,
    korpice: korpice,
    koverte: koverte,
    maturski: maturski,
    novogodisnji: novogodisnji,
    okrugle: okrugle,
    okrugleBezPoklopca: okrugleBezPoklopca,
    okrugleBezPoklopcaSaPregradom: okrugleBezPoklopcaSaPregradom,
    okrugleKlasik: okrugleKlasik,
    okrugleSaPregradom: okrugleSaPregradom,
    panorama: panorama,
    pinjate: pinjate,
    poklonGosti: poklonGosti,
    postoljaislagalice: postoljaislagalice,
    pravougaone: pravougaone,
    pravougaoneBezPoklopca: pravougaoneBezPoklopca,
    pravougaoneBezPoklopcaSaPregradom: pravougaoneBezPoklopcaSaPregradom,
    pravougaoneKlasik: pravougaoneKlasik,
    pravougaoneSaPregradom: pravougaoneSaPregradom,
    providne: providne,
    providneOkrugle: providneOkrugle,
    providnePravougaone: providnePravougaone,
    razno: razno,
    slovaibrojevi: slovaibrojevi,
    srce: srce,
    svadbeni: svadbeni,
    vencanjeikrstenje: vencanjeikrstenje
};