import React, {createContext, useContext, useState, useEffect} from 'react';
import {getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut} from "firebase/auth";
import {app} from '../firebase';
import axios from 'axios';

const StateContext = createContext();

export const ContextProvider = ({children}) => {

    const [allImages,
        setAllImages] = useState([]);
    const [imageFile,
        setImageFile] = useState([]);
    const [boxTypes,
        setBoxTypes] = useState([]);
    const [images,
        setImages] = useState([]);
    const [currentImage,
        setCurrentImage] = useState("");
    const [email,
        setEmail] = useState("");
    const [password,
        setPassword] = useState("");
    const auth = getAuth(app);
    const [errorMessage,
        setErrorMessage] = useState("");
    const [dimension,
        setDimension] = useState("");
    const [prices,
        setPrices] = useState([]);
    const [additionalLabel,
        setAdditionalLabel] = useState("");
    const dataUrl = "https://decorative-boxes-6255a-default-rtdb.firebaseio.com/data/-NLl9W4E3mD9xvDy" +
            "3TR7.json";
    const imagesUrl = "https://decorative-boxes-6255a-default-rtdb.firebaseio.com/data/images.json";
    // const appUrl = "http://localhost:3000/";
    const appUrl = "https://kutijeletus.com/";
    // const isLoggedIn = sessionStorage.getItem("isLoggedIn") === ("true" || true)
    // ? true : false;
    const isLoggedIn = localStorage.getItem("isLoggedIn") === ("true" || true)
        ? true
        : false;

    const handleFormChange = (e) => {
        if (e.id === "dimension") {
            setDimension(e.value);
        } else if (e.id === "item-label") {
            setAdditionalLabel(e.value);
        } else {
            const type = parseInt(e.id);
            const val = e.value;
            if (type !== undefined && val) {
                var index = -1;
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i].type === type) {
                        index = i;
                    }
                }
                if (index !== -1) {
                    if (prices[index]) {
                        prices[index].price = val;
                    }
                } else {
                    setPrices([
                        ...prices, {
                            type: type,
                            price: val
                        }
                    ]);
                }
            }
        }
    };

    const resetFormValues = () => {
        setDimension("");
        setPrices([]);
    };

    const signIn = () => {
        createUserWithEmailAndPassword(auth, email, password).then((userCredential) => {
            // Signed in ...
        }).catch((error) => {
            if (error.message) {
                setErrorMessage(error.message);
            }
        });
    }

    const signUp = () => {
        signInWithEmailAndPassword(auth, email, password).then(() => {
            localStorage.setItem('isLoggedIn', true);
            // sessionStorage.setItem('isLoggedIn', true);
            window
                .location
                .replace(appUrl);
        }).catch((error) => {
            if (error.message) {
                setErrorMessage(error.message);
            }
        });
    };

    const logout = () => {
        signOut(auth).then(() => {
            // Sign-out successful. sessionStorage.setItem('isLoggedIn', false);
            localStorage.setItem('isLoggedIn', false);
            window
                .location
                .reload();
        }).catch((error) => {
            // An error happened.
            console.log(error.message);
        });
    };

    const getImages = async(group) => {
        const res = await axios.get(imagesUrl);
        var data = await res.data;
        setAllImages(data);
        if (group) {
            data = data.filter((item) => item.substring(0, group.length) === group);
        }

        setImages(data);

        return data;
    };

    useEffect(() => {
        const getDataFromServer = async() => {
            const dataFromServer = await getData();
            setBoxTypes(dataFromServer);
        }
        getDataFromServer();
    }, []);

    const getData = async() => {
        const res = await fetch(dataUrl);
        const data = await res.json();

        return data;
    }

    const getCurrentProduct = (id) => {
        return boxTypes.find((item) => item.id === id);
    }

    const deleteDimension = async(currentDimension, id) => {
        const currProduct = getCurrentProduct(id);

        const newProduct = currProduct
            .info
            .filter((item) => item.dimension !== currentDimension.dimension);

        boxTypes[id].info = newProduct;

        await fetch(dataUrl, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },

                body: JSON.stringify(boxTypes)
            })
            .then(response => response.json())
            .then((data) => {
                setBoxTypes(data);
            })
    };
    const editDimension = async(id, currentDimension, currentPrices) => {
        const currentProduct = boxTypes[id];

        for (let i = 0; i < prices.length; i++) {
            for (let j = 0; j < currentPrices.length; j++) {
                if (currentPrices[j].type === prices[i].type) {
                    currentPrices[j].price = prices[i].price;
                }
            }
        }

        const object = {
            dimension: currentDimension,
            prices: currentPrices,
            label: additionalLabel
        };

        for (let i = 0; i < currentProduct.info.length; i++) {
            if (currentProduct.info[i].dimension === currentDimension) {
                currentProduct.info[i] = object;
            }
        }

        boxTypes[id].info = currentProduct.info;

        await fetch(dataUrl, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },

                body: JSON.stringify(boxTypes)
            })
            .then(response => response.json())
            .then((data) => {
                setBoxTypes(data);
            });
    };

    const addDimension = async(productID) => {
        const currentProduct = boxTypes[productID]
        var isExist = true;
        if (currentProduct.info) {
            isExist = currentProduct
                .info
                .find((item) => item.dimension === dimension);
        } else {
            isExist = false;
            boxTypes[productID].info = [];
        }

        if (!isExist) {
            const object = {
                dimension: dimension,
                prices: prices,
                label: additionalLabel
            };

            boxTypes[productID]
                .info
                .push(object);

            await fetch(dataUrl, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },

                    body: JSON.stringify(boxTypes)
                })
                .then(response => response.json())
                .then((data) => {
                    setBoxTypes(data);
                });
        }
    }

    const handleUpload = () => {
        axios
            .post("https://server.kutijeletus.com/image", imageFile)
            .then((response) => {
                axios
                    .put(imagesUrl, allImages)
                    .then((response) => {
                        if (response.status === 200) {
                            const newImage = imageFile
                                .get("image")
                                .name;
                            setImages((prev) => [
                                ...prev,
                                newImage
                            ]);
                            alert("Slika je uspešno dodata");
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        alert("Slika nije uspešno dodata");
                    });
            })
            .catch((error) => {
                console.error(error);
                alert("Slika nije uspešno dodata");
            });
    };

    const getFileInfo = (e, typeName) => {
        const fileName = `${typeName}_${e.target.files[0].name}`;
        const formData = new FormData();
        formData.append("image", e.target.files[0], fileName);

        allImages.push(fileName);
        setAllImages(allImages);
        setImageFile(formData);
    };

    const onImageClicked = (event) => {
        if (isLoggedIn) {
            const currImg = event
                .target
                .src
                .replace(appUrl + "images/", "");
            setCurrentImage(currImg);
            const selected = " border border-4 border-danger";
            if (event.target.className.includes(selected)) {
                event.target.className = event
                    .target
                    .className
                    .replace(selected, "");
            } else {
                event.target.className += selected;
            }
        }
    };

    const deleteImage = async() => {
        const newImagesArray = images.filter((item) => item !== currentImage);
        const newAllImagesArray = allImages.filter((item) => item !== currentImage);
        // setImages(newImagesArray); setAllImages(newAllImagesArray);
        setImages(newImagesArray);
        await axios.put(imagesUrl, newAllImagesArray).then((response) => {
            console.log(response);
            if (response.status === 200) {
                setAllImages(newAllImagesArray);
                alert("Slika je uspešno obrisana");
                window.location.reload();
            }
        }).catch((error) => {
            console.error(error);
            alert("Slika nije uspešno obrisana");
        });
    };

    return (
        <StateContext.Provider
            value={{
            boxTypes,
            setBoxTypes,
            getCurrentProduct,
            deleteDimension,
            editDimension,
            addDimension,
            setEmail,
            setPassword,
            signIn,
            signUp,
            logout,
            isLoggedIn,
            errorMessage,
            dimension,
            prices,
            handleFormChange,
            resetFormValues,
            getImages,
            appUrl,
            images,
            handleUpload,
            getFileInfo,
            onImageClicked,
            deleteImage
        }}>
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () => useContext(StateContext);
