import {useEffect} from 'react';
import {useStateContext} from '../contexts/ContextProvider';
import {Layout} from '../components';
import noImage from '../data/no-image.png';

const Gallery = () => {
    const {getImages, boxTypes, images, appUrl} = useStateContext();

    useEffect(() => {
        getImages();
    }, [boxTypes]);

    return (
        <Layout>
            <div className="container py-5">
                <div className="portfolio-item row">
                    {images.length
                        ? images.map((url, index) => (
                            <div key={index} className="item selfie col-lg-3 col-md-4 col-6 py-2">
                                <a href={`${appUrl}images/${url}`} target="_blank" rel="noreferrer" className="fancylight popup-btn" data-fancybox-group="light">
                                    {/* img-fluid removed from className */}
                                    <img id="gallery-img" src={`${appUrl}images/${url}`} alt=""/>
                                </a>
                            </div>
                        ))
                        : <img src={noImage} className="d-block w-100" alt="..." height="500"/>
                    }
                </div>
            </div>
        </Layout>
    )
}

export default Gallery
