import { useEffect } from 'react';
import {useParams} from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import { useStateContext } from '../contexts/ContextProvider';
import {Layout, PricelistTable, ModalDialog} from '../components';
import noImage from '../data/no-image.png';

const Product = () => {
    const params = useParams();
    const {boxTypes, addDimension, getImages, handleFormChange, isLoggedIn, images, appUrl, getFileInfo, handleUpload, onImageClicked, deleteImage} = useStateContext();
    const productID = parseInt(params.id);

    useEffect(() => {
        if(boxTypes) {
            if(boxTypes.length){
                console.log(boxTypes[productID].src);
                getImages(boxTypes[productID].src);
            }
        }
    }, [boxTypes]);

    const showNewForm = (product) => {
        var row = product.types;
        return (
            <form>
                <div className="mb-3">
                    <label htmlFor="dimension" className="form-label">Dimenzija</label>
                    <input type="text" className="form-control" id="dimension" placeholder="NxM" onChange={(e) => handleFormChange(e.target)} />
                </div>
                <div className="mb-3">
                    <label htmlFor="item-label" className="form-label">Naziv</label>
                    <input type="text" className="form-control" id="item-label" placeholder="Dodatni naziv" onChange={(e) => handleFormChange(e.target)} />
                </div>
                {row.map((price, index) => (
                        <div className="mb-3" key={index}>
                            <label htmlFor={price.type} className="form-label">{price.label}</label>
                            <input
                                type="text"
                                className="form-control"
                                id={price.type}
                                placeholder="RSD"
                                onChange={(e) => handleFormChange(e.target)}/>
                        </div>
                    ))}
            </form>
        );
    };

    return (
        <Layout>
            {boxTypes.length &&
            <>
                {isLoggedIn && <div className="d-flex justify-content-end" id="gallery-actions">
                    <input type="file" onChange={(e) => getFileInfo(e, boxTypes[productID].src)}/>
                    <button type="button" className="btn btn-primary mx-1" onClick={handleUpload}>Dodaj Sliku</button>
                    <button type="button" className="btn btn-danger mx-1" onClick={deleteImage}>Obriši Sliku</button>
                </div>}
                <div id="product-carousel" className="row justify-content-center mt-5 mx-1">
                    <div className="col-lg-4">
                        {images && images.length ? 
                        <Carousel className="shadow-lg" interval={isLoggedIn ? null : 3000}>
                            {images.map((item, index) => (
                                <Carousel.Item key={index}>
                                    <img src={`${appUrl}images/${item}`} className="d-block w-100" alt="..." onClick={(e) => onImageClicked(e)}></img>
                                </Carousel.Item>
                            ))}
                        </Carousel> : <img src={noImage} className="d-block w-100" alt="..." height="500" />
                        }
                    </div>
                    <div id="info-container" className="d-flex justify-content-center my-5 pt-5">
                        {boxTypes[productID].info ? <PricelistTable productId={productID} /> : <div>Ne postoji cenovnik za ovaj proizvod</div>}
                        {isLoggedIn && <ModalDialog modalContent={ showNewForm(boxTypes[productID]) } title="Dodavanje cena" buttonActionLabel="Sačuvaj" onBtnAction={(e) => addDimension(productID)}>Dodaj cene</ModalDialog>}
                    </div>
                </div>
            </>
            }
        </Layout>
    )
}

export default Product
